export const YOUR_PUBLIC_KEY = "fKpPsuTMAw0JwZGsQ"
export const YOUR_TEMPLATE_ID = "template_5kohtgf"
export const YOUR_SERVICE_ID = "service_7nisf8i"
// export const Sheet_Connection_Url = "https://sheet.best/api/sheets/c7dd82b4-6623-4399-9b75-3004a18b8b43"
// export const Sheet_Connection_Url = "https://script.google.com/macros/s/AKfycbzAaccIZEPRvqGKyOpe7XSNyqMYRt7BkziUY4QIsPwUjueIFiMXar2eEXx6oRXAvPAx/exec?action=addUser"
export const Connection_Url = "https://www.petorosealcones.com"
// export const Connection_Url = "http://localhost:7003"

export  const topScroll = ()=>{
    window.scrollTo(0,0)
  }