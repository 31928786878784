import React from 'react'
import { FaCalculator, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'
import { MdOutlineArrowRight } from 'react-icons/md'
import { BsSend } from 'react-icons/bs'
import './footer.css'
import Modal from 'react-modal';
// const fda = 'fda';
import fda from '../images/Certifications/FDA PAPL.pdf'
import harlal from '../images/Certifications/HALAL PAPL.pdf'
import iso90 from '../images/Certifications/ISO 9001 PAPL.pdf'
import iso14 from '../images/Certifications/ISO 14001 PAPL.pdf'
import iso22 from '../images/Certifications/ISO 22000 PAPL.pdf'
import kosher from '../images/Certifications/KOSHER PAPL.pdf'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '70vw',
        height: '96vh',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Footer = () => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [url, setUrl] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    const openHandle = (url) => {
        setUrl(url)
        openModal()
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <footer>
            <div className="footerContainer">

                {/* <div className="subscribeBox">
                <h2>Subscribe Now!</h2>
                <div className='subscribeInp'>
                    <input type="text" placeholder='Gmail' />
                    <BsSend/>
                </div>
                <div className='contactBoxs'>
                    <FaCalculator/>
                    <span>
                    <p>Call us 24/7 :</p>
                    <p>(+62) 0123 567 789</p>
                    </span>
                </div>
            </div> */}
                <div className="topBox">
                    <div className="box1">
                        <div className="Box1heading">CONTACT US</div>
                        <div>
                            <p ><b className="" >Petorose Alcones Pvt Ltd</b></p> <br />
                            <p >Address- B-522, RIICO Industrial Area, Bhiwadi, Dist. Alwar, Rajasthan - 301 019 INDIA
                            </p> <br />


                            <p><b className="colorMidDark" >Phone</b> : +91 9811290445, +91 8860609772</p>
                            <p><b className="colorMidDark" >Email</b> :  contact@petorosealcones.com</p>
                        </div>
                    </div>
                    <div className="box2">
                        <div className="Box2heading">Important Links</div>
                        <div><a className="atag" target='_black' href="https://www.petorosealcones.com/"> <MdOutlineArrowRight />  Home</a></div>
                        <div><a className="atag" target='_black' href=""><MdOutlineArrowRight />   Products</a></div>
                        <div><a className="atag" target='_black' href="https://www.petorosealcones.com/about.html"><MdOutlineArrowRight /> About us</a></div>
                        <div><a className="atag" target='_black' href="https://www.petorosealcones.com/contact.html"><MdOutlineArrowRight />  Contact us</a></div>
                        {/* <div><a  className="atag" href=""><MdOutlineArrowRight/>  Entrepreneur</a></div> */}
                    </div>
                    <div className="box2">
                        <div className="Box2heading">certifications</div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(fda)} > <MdOutlineArrowRight />  FDA</a></div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(harlal)} ><MdOutlineArrowRight />   HALAL</a></div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(iso90)} ><MdOutlineArrowRight /> ISO 90001 </a></div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(iso14)} ><MdOutlineArrowRight />  ISO 14001</a></div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(iso22)} ><MdOutlineArrowRight />  ISO 22000</a></div>
                        <div><a className="atag" target='_black' onClick={e => openHandle(kosher)} ><MdOutlineArrowRight />  KOSHER</a></div>
                        {/* <div><a  className="atag" href=""><MdOutlineArrowRight/>  Entrepreneur</a></div> */}
                    </div>
                    <div className="box3">
                        {/* <div className="Box2headin"></div> */}

                        <div className="s">
                            <img src={require('../images/MONO.jpg')} style={{ width:'100%', display: 'block', margin: 'auto',mixBlendMode:'darken' }} height={'100%'} alt="logoa" />
                            {/* <h1>Petrose Alcones Private Limited</h1> */}
                            {/* <p>International Conference on environment Climate change and Renewable energy</p> */}
                            {/* <div className="downRightBox ">
                                <a rel="noreferrer" target='_blank' href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram /></a>
                                <a rel="noreferrer" target='_blank' href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin />Linkedin</a>
                                <a rel="noreferrer" target='_blank' href={"https://wa.me/9953179645"}><FaWhatsapp /></a>
                            </div> */}
                        </div>

                    </div>
                </div>

                <div className="downBox">
                    <div className="downLeftBox">
                        <p>© Copyright <b>©2019-2024 by Petrose Alcones Private Limited.</b> All Rights Reserved <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/383px-Flag_of_India.svg.png" width={22} alt="" /> Made in India  </p>
                        <p>Developed and maintained by  
                        <a rel="noreferrer" target='_blank' href="http://www.designavenue.co.in/" style={{color:'white'}}> Design Avenue</a>
                        </p>
                    </div>
                    {/* <div className="downRightBox ">
                <a rel="noreferrer"  target='_blank' href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram/></a>
                <a rel="noreferrer"  target='_blank' href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin/></a>
                <a rel="noreferrer"  target='_blank' href={"https://wa.me/9953179645"}><FaWhatsapp/></a>
                </div> */}
                </div>
            </div>

            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    className={'modal'}
                    contentLabel="Example Modal"
                >
                    <div className='closeBox'>
                        <button onClick={closeModal}>close</button>
                    </div>
                    <iframe src={url} alt="" width={'100%'} height={'94%'} />
                </Modal>
            </div>
        </footer>
    )
}

export default Footer