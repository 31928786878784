import React, { useState } from "react";
import { FaDownload, FaInstagram, FaLinkedin, FaSearch, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { BiMenuAltRight } from "react-icons/bi";
import "./Header.css";
import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom'/

const Header = () => {

  const [isHide, setisHide] = useState(true);
  const [isHideSearch, setisHideSearch] = useState(true);

  const searchHandle = ()=>{
    let node = document.getElementById("search")
      if(isHideSearch){
        node.classList.remove("hideSearch");
        node.classList.add("showSearch");
        setisHideSearch(false)
      }else{
        node.classList.remove("showSearch");
        node.classList.add("hideSearch");
        setisHideSearch(true)

      }
  }

  const menuHandler = (e) => {
    if (isHide) {
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("hide");
      document.getElementsByClassName("menuContainer")[0].classList.add("show");
      setisHide(false);
    } else {
      document.getElementsByClassName("menuContainer")[0].classList.add("hide");
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("show");

      setisHide(true);
    }
  };

  return (
    <div className="HeaderContainerMain">
      <header>
        <div className="HeaderContainer">
          <div className="logoBox">
            <img
              src={require('../images/petroseLogo.jpg')}
              // width="100%"
              alt="logo"
              style={{mixBlendMode:'darken'}}
            />
            <img
              src={require('../images/TYPEFACEE.png')}
              // width="100%"
              alt="logo"
              // style={{mixBlendMode:'darken'}}
            />
            {/* <h3>Petrose Alcones</h3> */}
          </div>
          <div className="linkBox">
            <Link  to={"/"} className="activ e ">
              Home
            </Link >
            {/* <a  to={"/products"}>Products</a > */}
            <Link  to={"/products"}>Products</Link >
            <Link  to={"/about"}>About us</Link >
            <Link  to={"contact"}>Contact us</Link >
            <a href={require('../images/petorose-jul-24.pdf')} download={'petrose'} className="BroBtn"><FaDownload/>Brochure</a>
          </div>
          {/* <div className="iconBox">
        
            <a href="https://www.instagram.com/invites/contact/?i=1eyki4m3i00vf&utm_content=q05ecy3" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://youtube.com/@sauravkori217" target="_blank">
              <FaYoutube />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={"https://wa.me/72918 54677"}
            >
              <FaWhatsapp />
            </a>
            
          </div> */}
          <div onClick={() => menuHandler()} className="menu">
            <BiMenuAltRight />
          </div>
        </div>

        {/* <div className="hideSearch searchBox " id="search">
            <div>
              <input type="text" placeholder="search tools"  />
              <input type="submit" value={'Find'} />
            </div>
        </div> */}

        <div className="menuContainer hide">
          <div>
          <Link onClick={() => menuHandler()}to={'/'} className="activ e">
              Home
            </Link>
            <Link onClick={() => menuHandler()} to={"/products"}>Products</Link>
            <Link onClick={() => menuHandler()} to={"/about"}>About us</Link>
            <Link onClick={() => menuHandler()} to={"/contact"}>Contact us</Link>
            <a href={require('../images/Brochure.pdf')} download={'petrose'} className="BroBtn"><FaDownload/>Brochure</a>
          </div>

          {/* <div className="iconBoxx">
         
            <a href="https://www.instagram.com/invites/contact/?i=1eyki4m3i00vf&utm_content=q05ecy3" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://youtube.com/@sauravkori217" target="_blank">
              <FaYoutube />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={"https://wa.me/72918 54677"}
            >
              <FaWhatsapp />
            </a>
            
          </div> */}
        </div>
      </header>
    </div>
  );
};

export default Header;
