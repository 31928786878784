import React from 'react'
import './homePage.css'
import { Link } from 'react-router-dom'
import { MdOutlineSettingsSuggest } from 'react-icons/md'
import { FaBusinessTime, FaCartPlus } from 'react-icons/fa'

const HomePage = () => {
  return (
    <div className='homePageMainContainer '>
      <div className="topSection">
        <div className="containBox"> 
          <h1>INNOVATING AROMAS</h1>
          {/* <p>Chemical compounds or single molecules that impart a scent or a flavor.</p> */}
          <Link className='button' to={'/about'}>Know More</Link>
        </div>

      </div>

      <div>
        <div className='heading'>
          <h1 >New Products</h1>
        </div>
        <div className="home-product-Box">
          <Link to={'product/30'} className="productItem">
            <img src={require('../images/petrose/Rhumytal.jpg')} alt="Rhumytal" />
            <p>Rhumytal (Rhum Acetal)</p>
          </Link>

          <Link to={'product/8'} className="productItem">
            <img src={require('../images/petrose/Fadeus.jpg')} alt="Fadeus" />
            <p>Fadeus (Diola) </p>
          </Link>

          <Link to={'product/19'} className="productItem">
            <img src={require('../images/petrose/Methyl-3-Nonanoate.jpg')} alt="Methyl-3-Nonenoate" />
            <p>Methyl-3-Nonenoate</p>
          </Link>
        </div>
      </div>

      {/* <div className='Section2'>
        <div className="s2Box">
          <h1>Introducing Anethole</h1>
          <p>Our Anethole production capacity is 40 MT/month.
            Being the chielf component of star anise, anise seed oil and sweet fennel, Anethole has a sweet and mildly spicy, floral and fruity, anisic-balsimic odour.</p>
          <Link to={'/about'} className='button'>Know More</Link>
        </div>
      </div> */}


      <div className=''>
        <div className="s2Box1">
          {/* <h3> Upcoming Event </h3> */}
          <img src={require('../images/web-banner.png')} alt="" />
        </div>

      </div>
      <br />


      <br />

      <div>
        <div className='heading'>
          <h1 >Featured Products</h1>
        </div>
        <div className="home-product-Box">
          <Link to={'product/3'} className="productItem">
            <img src={require('../images/petrose/Anethole.jpg')} alt="Anethole" />
            <p>
              Anethole (Anethole 21/22)</p>
          </Link>

          <Link to={'product/22'} className="productItem">
            <img src={require('../images/petrose/Newfoleon.jpg')} alt="Newfoleon" />
            <p>Newfoleon (Neofolione/ Novafoleon)</p>
          </Link>
          <Link to={'product/2'} className="productItem">
            <img src={require('../images/petrose/Chavibetol 50_.jpg')} alt="Chavibetol 50" />
            <p>Chavibetol 50% </p>
          </Link>
        </div>
      </div>
      <br />

      <div className='center'>
        <Link to={'/products'} className='button btnBg'>All Products</Link>
      </div>
      <br />
      <br />
      <br />

    </div>
  )
}

export default HomePage