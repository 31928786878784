import React, { useEffect, useState } from 'react'
import './ProductPage.css'
import { Carousel } from 'react-responsive-carousel'
import ProductItem from '../Components/ProductItem'
import OpenModal from '../Components/OpenModal.js'
import { Link, useParams } from 'react-router-dom'
import items from '../utils/products'
import { TfiAlarmClock } from 'react-icons/tfi'

const ProductPage = () => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    const { id } = useParams()
    // const item = items[id - 1]
    const [item, setitem] = useState()

    useEffect(() => {
      setitem(items[id - 1])
    }, [id,item])
    

    const colorChitchHandler = (color) => {
        document.getElementsByClassName('productPageContainer')[0].style.backgroundColor = color
        // document.getElementsByClassName('productBox')[0].style.backgroundColor = color
        let textColor = document.getElementsByClassName('textColor')


        if (color == 'black') {
            for (let elm of textColor) {
                elm.style.color = 'white'
            }
        } else {
            for (let elm of textColor) {
                elm.style.color = 'black'
            }
        }

    }

    return (
        <div className="productPageContainer">
            <div className="colorSwitch">
                <span id='black' onClick={e => colorChitchHandler('black')}></span>
                <span id='white' onClick={e => colorChitchHandler('white')}></span>
            </div>

            <div className='Box'>
                <div className="leftBox">
                        <h2>Products</h2>
                    <div className="productList">
                        {items && items.map((item, i) =>
                            <Link to={`/product/${item?.id}`} className={`${i % 2 ? 'bg1' : 'bg2'}`}>
                                {item.title}
                            </Link>
                        )}
                    </div>
                </div>
                <div className='rightBox'>
                    <div className='productBox'>
                        <div className="imgbox">
                            {/* <Carousel showArrows={false} autoPlay={true} infiniteLoop={true}> */}
                            <img src={item?.img} alt="tool" />
                            {/* </Carousel> */}
                        </div>
                        <div className="productDetailBox">
                            {/* <span> */}
                            <h2 className='h2Sty'>{item?.title}</h2>
                            <div className='lineSty'></div>
                            <h2 className='h4Sty'>{item?.subTitle}</h2> <br />

                            {/* <h3 className='h3Sty'>Details</h3> */}

                            <p className='ptag textColor'>{item?.about}</p>
                            {/* </span> */} <br />
                            <p className='textColor'> <span>Chemical Name : </span>{item?.chemicalName}</p>
                            <br />
                            <p> <span> Physico Chemical
                                Properties:
                            </span> </p>
                            <p className='textColor'> <pre className='textColor'>Formula - {item?.formula}</pre></p>
                            {
                                item?.caseNo && (<p className='textColor'>CAS no.- {item?.caseNo}</p>)
                            }
                            {
                                item?.FEMA !== "" && (<p className='textColor'>FEMA- {item?.FEMA}</p>)
                            }

                            <div className="actionBox">
                                <button className='buybtn' onClick={openModal}>Enquiry</button>
                            </div>
                        </div>

                    </div>

                    <div className='productBox'>
                        <div className='sec2Box1'>
                            <div className='clock'>
                                <TfiAlarmClock className='textColor' />
                                <div >
                                    <h2 className='textColor'>{item?.TENACITYHour} HOURS</h2>
                                    <p className='textColor'>TENACITY</p>
                                </div>
                            </div>
                            <img src={item?.foodGradeUrl} width={120} alt="" />
                        </div>

                        <div className='sec2Box2'>
                            {/* <img src={require(`${item.chemicalUrl}` )} alt="altanol" /> */}
                            <img src={item?.chemicalUrl} alt="altanol" />
                        </div>

                    </div>

                </div>
            </div>

            <OpenModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} item={item} />

            {/* <div className='line'></div> */}
            {/* <hr /> */}
            {/* 
            <div className="productsBox">
                <h1>Related Products</h1>
                <div className="products-row-scroll">
                    {items && items.map((item) => <ProductItem item={item} />)}
                </div>
            </div> */}

        </div>
    )
}

export default ProductPage