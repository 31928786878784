import React from 'react'
import './AboutUs.css'
// import { FaBusinessTime, FaCartPlus, FaEye, FaGlobe } from 'react-icons/fa'
// import { MdOutlineChangeCircle, MdOutlineSettingsSuggest } from 'react-icons/md'
// import { GrTechnology } from 'react-icons/gr'

const AboutUs = () => {
    return (
        <div className='aboutContainer'>
            {/* <div className="sec1Layer aboutusBg">
                <div className="sec1Heading">
                    <h2 className="">About Us</h2>
                </div>
            </div> */}
            {/* 
            <div>
                <div className="borderPatti2">
                    <p>Experience Our Quality Products!</p>
                </div>

                <div className="visionBox">
                    <div className="visionItem1 c1">
                        <FaEye />
                        <h1>Vision</h1>
                        <p>Our motto has always been complete customer satisfaction and our essential oils have been of the utmost highest quality.For almost four decades, the company’s directors have been in the business of mints for both domestic and international markets</p>
                    </div>

                    <div className="visionItem1 c2">
                        <MdOutlineChangeCircle />
                        <h1> Revenue</h1>
                        <p>With Indosin Ingredients, we're entering a major segment of the industry including Herbal Extracts and Oleoresins, alongside Essential Oils, Absolutes and Aroma Chemicals. Our group turnover is over USD 30 million</p>
                    </div>

                    <div className="visionItem1 c3">
                        <FaGlobe />
                        <h1>Technology</h1>
                        <p>The company is a highly qualified exporter and importer of 100% Pure and Natural essential oils and is situated in New Delhi, India. Our essential oils go through severe examinations using the latest technology and advanced GC-MS machines.</p>
                    </div>
                </div>
            </div> */}

            <div className='AboutSection2'>
                <div className="AboutS2Box">
                    <h1>About Us</h1>
                    <p>Because INNOVATION is the only way forward</p>
                    {/* <button className='button'>Know More</button>  */}
                </div>
            </div>


            {/* <div>

                <div className="borderPatti2">
                    <p>Our industries & expertise</p>
                </div>

                <div className="visionBox">
                    <div className="visionItem">
                        <MdOutlineSettingsSuggest />
                        <h1>Machinery</h1>
                        <p>We use latest technology to
                            extract essential oils from our
                            machinery.</p>
                    </div>

                    <div className="visionItem">
                        <FaBusinessTime />
                        <h1>B2B</h1>
                        <p>We use latest technology to
                            extract essential oils from our
                            machinery.</p>
                    </div>

                    <div className="visionItem">
                        <FaCartPlus />
                        <h1>Products</h1>
                        <p>We use latest technology to
                            extract essential oils from our
                            machinery.</p>
                    </div>
                </div>
            </div> */}


            <div className='aboutContainerText'>
                <div className='about-Box'>
                    <div className='aboutText'>
                        Petorose Alcones Pvt Ltd, formerly Banwari Chemicals Pvt Ltd, is a family-owned and operated Banwari Group based in New Delhi, India. Petorose Alcones specializes in manufacturing Aroma Chemicals and Aroma Ingredients, set up in the RIICO Industrial Area of the state of Rajasthan in India. The facility is easily accessible from the Indira Gandhi International Airport of New Delhi, being about an hour's drive away.
                    </div>
                    <div className='aboutImag'>
                        <img src={'https://mygardenlife.com/wp-content/uploads/2022/12/2888_13-2.jpg'} alt="about Us" />
                    </div>
                </div>

                <div className='about-Box'>
                    <div className='aboutImag'>
                        <img src={'https://5.imimg.com/data5/SELLER/Default/2023/8/339305591/XX/JQ/LH/54770746/anethole-essential-oil-500x500.jpg'} alt="about Us" />
                    </div>
                    <div className='aboutText'>
                        At Petorose Alcones, we are deeply committed to innovating aromas, which precisely is what defines us. As a part of a 40 years old business group, we believe that our actions make us who we are and we are no more than the trust our clients have in us.
                        With being India’s first manufacturer of purest Anethole, comes responsibility to build better. To shoulder such a huge responsibility with ‘MAKE IN INDIA’ at our core, we’ve aligned our goals to become India’s most trusted manufacturer of Specialty Aroma Chemicals and Ingredients.
                    </div>

                </div>

                <div className='about-Box'>
                    <div className='aboutText'>
                        Petorose Alcones is led by industry experts and veterans with years of experience, unshakable hands in the lab and unwavering spirit to innovate makes us the leader in the field of Aroma Chemicals. Our scientists and researchers have spent hours playing with chemicals and ingredients to create products out of the ordinary, so that you may enhance your fragrances and flavours. The products are designed and developed in an all-compliant international grade highly specialized facility, ensuring all certifications like Halal and Kosher.
                    </div>
                    <div className='aboutImag'>
                        <img src={'https://shop.perfumersapprentice.com/images/product/large/9290.jpg'} alt="about Us" />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default AboutUs